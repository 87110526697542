<template>
  <b-card v-if="proveedorData">
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon
        icon="UserIcon"
        size="19"
      />
      <h4 class="mb-0 ml-50">
        Información general
      </h4>
    </div>

    <!-- Form: Personal Info Form -->
    <validation-observer
      ref="refFormObserver"
      #default="{ handleSubmit }"
    >
      <b-form
        class="mt-1"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <b-row>
          <!-- Field: Nombre Comercial-->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <validation-provider
              #default="validationContext"
              name="Nombre Comercial"
              rules="required"
            >
              <b-form-group
                label="Nombre Comercial"
                label-for="nombre-comercial"
              >
                <b-form-input
                  v-model="proveedorData.nombre_comercial"
                  class="form-control"
                  placeholder="Comercial Name"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Nombre Fiscal -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <validation-provider
              #default="validationContext"
              name="Nombre Fiscal"
              rules="required"
            >
              <b-form-group
                label="Nombre Fiscal"
                label-for="nombre-fiscal"
              >
                <b-form-input
                  id="nombre-fiscal"
                  v-model="proveedorData.nombre_fiscal"
                  placeholder="Fiscal Name"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: CIF -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <validation-provider
              #default="validationContext"
              name="CIF"
              rules="required"
            >
              <b-form-group
                label="CIF"
                label-for="cif"
              >
                <b-form-input
                  id="cif"
                  v-model="proveedorData.cif"
                  placeholder="CIF"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Header: Contato -->
        <div class="d-flex mt-2">
          <feather-icon
            icon="PhoneIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            Contacto
          </h4>
        </div>

        <b-row>
          <!-- Field: Contacto -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <validation-provider
              #default="validationContext"
              name="Contacto"
              rules="required"
            >
              <b-form-group
                label="Contacto"
                label-for="contacto"
              >
                <b-form-input
                  id="contacto"
                  v-model="proveedorData.contacto"
                  placeholder="Contacto"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Email -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <validation-provider
              #default="validationContext"
              name="Email"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="proveedorData.email"
                  placeholder="johndoe@email.com"
                  :state="getValidationState(validationContext)"
                  required
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
                <small>Para añadir más de un email, escribirlos sin espacios y separados por punto y coma</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Telefono -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <validation-provider
              #default="validationContext"
              name="Telefono"
              rules="required"
            >
              <b-form-group
                label="Telefono"
                label-for="telefono"
              >
                <b-form-input
                  id="telefono"
                  v-model="proveedorData.telefono"
                  placeholder="12346789"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Movil -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <validation-provider
              #default="validationContext"
              name="Movil"
              rules="required"
            >
              <b-form-group
                label="Movil"
                label-for="movil"
              >
                <b-form-input
                  id="movil"
                  v-model="proveedorData.movil"
                  placeholder="123456789"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Header: Address -->
        <div class="d-flex mt-2">
          <feather-icon
            icon="MapPinIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            Dirección
          </h4>
        </div>

        <b-row class="mt-1">
          <!-- Field: Direccion-->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <validation-provider
              #default="validationContext"
              name="Direccion"
              rules="required"
            >
              <b-form-group
                label="Direccion"
                label-for="direccion"
              >
                <b-form-input
                  id="direccion"
                  v-model="proveedorData.direccion"
                  placeholder="Direccion"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Poblacion -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <validation-provider
              #default="validationContext"
              name="Poblacion"
              rules="required"
            >
              <b-form-group
                label="Poblacion"
                label-for="poblacion"
              >
                <b-form-input
                  id="poblacion"
                  v-model="proveedorData.poblacion"
                  placeholder="Poblacion"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Provincia -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <validation-provider
              #default="validationContext"
              name="Provincia"
              rules="required"
            >
              <b-form-group
                label="Provincia"
                label-for="provincia"
              >
                <b-form-input
                  id="provincia"
                  v-model="proveedorData.provincia"
                  placeholder="Provincia"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <hr style="border-top: 1px solid gainsboro">

        <b-row class="pl-1">
          <!-- Fields: estado -->
          <p
            class="mr-1"
            style="font-weight: bold"
          >
            ¿Es un provedor ativo?
          </p>
          <b-form-checkbox
            v-model="proveedorData.estado"
            name="check-button"
            switch
          >
            <span style="font-weight: 500">{{
              proveedorData.estado ? "Sí" : "No"
            }}</span>
          </b-form-checkbox>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              type="submit"
            >
              Guardar cambios
            </b-button>
            <b-button
              variant="outline-secondary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :to="{ name: 'proveedores-list' }"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BButton,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import router from '@/router'
import store from '@/store'
import proveedorStoreModule from './proveedorStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },

  methods: {
    onSubmit() {
      this.$store
        .dispatch('app-proveedor/addProveedor', this.proveedorData)
        .then(() => {
          this.$router.push({ name: 'proveedores-list' })
        })
    },
  },

  setup() {
    const blankproveedorData = {
      nombre_comercial: '',
      nombre_fiscal: '',
      email: '',
      cif: '',
      direccion: '',
      poblacion: '',
      provincia: '',
      contacto: '',
      telefono: '',
      movil: '',
      estado: false,
    }
    const proveedorData = ref(null)

    const PROVEEDOR_APP_STORE_MODULE_NAME = 'app-proveedor'

    const resetproveedorData = () => {
      proveedorData.value = JSON.parse(JSON.stringify(blankproveedorData))
    }

    // Register module
    if (!store.hasModule(PROVEEDOR_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        PROVEEDOR_APP_STORE_MODULE_NAME,
        proveedorStoreModule,
      )
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROVEEDOR_APP_STORE_MODULE_NAME)) store.unregisterModule(PROVEEDOR_APP_STORE_MODULE_NAME)
    })

    if (router.currentRoute.params.id !== 'new') {
      store
        .dispatch('app-proveedor/fetchProveedor', router.currentRoute.params.id)
        .then(response => {
          proveedorData.value = response
        })
        .catch(error => {
          if (error.response.status === 404) {
            proveedorData.value = undefined
          }
        })
    } else {
      proveedorData.value = blankproveedorData
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetproveedorData)

    return {
      proveedorData,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style></style>
